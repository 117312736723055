<template>
  <DialogCreateProduct
    :customersList="customersList"
    :productTypesList="productTypesData"
    ref="form"
    @on-done="$emit('on-done')"
  />
</template>
<script>
import DialogCreateProduct from "@/pages/Quanlythongtin/Sanpham/create-edit";
import { getAllProductTypes } from "@/api/loaisanpham";
export default {
  components: {
    DialogCreateProduct,
  },
  props: ["customersList"],
  data: () => ({
    productTypesData: [],
    loading: false,
  }),
  created() {
    this.getProductTypesData();
  },
  methods: {
    createItem(params = {}) {
      this.$refs.form.showFormAdd(params);
    },
    async getProductTypesData() {
      this.loading = true;
      try {
        let data = await getAllProductTypes();
        this.productTypesData = data;
        this.productTypesData.forEach((e) => {
          e.ten = e.name;
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
