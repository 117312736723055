<template>
  <PageListLayout
    icon="mdi-map-marker-outline"
    :title="title"
    noFilter
    useBack
    :linkBack="linkBack"
  >
    <template slot="side-right">
      <div class="mr-4">
        <v-btn
          v-if="!readonly"
          depressed
          color="success"
          @click="handleSubmit"
          :loading="btnLoading"
          :disabled="loadingData"
        >
          <v-icon left>
            {{ editing ? "mdi-account-edit" : "mdi-account-plus" }}</v-icon
          >
          {{ editing ? "Cập nhật thông tin" : "Thêm mới" }}
        </v-btn>
      </div>
    </template>
    <v-form ref="form">
      <v-row>
        <v-col cols="3">
          <div class="label-form">Ngày hoạch toán</div>
          <DateTimePickerComponent
            v-model="form.ngay_hoach_toan"
            placeholder="Ngày hoạch toán"
            :readonly="readonly"
          />
        </v-col>
        <v-col cols="3">
          <div class="label-form">Số chứng từ</div>
          <v-text-field
            v-model="form.so_chung_tu"
            placeholder="Số chứng từ"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details="auto"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="3">
          <div class="label-form">Mã nhà cung cấp</div>
          <v-text-field
            v-model="form.ma_nha_cung_cap"
            placeholder="Mã nhà cung cấp"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details="auto"
            readonly
          ></v-text-field>
        </v-col> -->
        <v-col cols="3">
          <div class="label-form">Nhà cung cấp</div>
          <v-autocomplete
            v-model="form.nha_cung_cap_id"
            :loading="isLoadingKhachHang"
            :items="khachhangs"
            placeholder="Nhà cung cấp"
            :item-text="(item) => `${item.code} - ${item.name}`"
            item-value="id"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details="auto"
            :readonly="readonly"
          >
            <template v-if="!readonly" v-slot:append-outer>
              <v-slide-x-reverse-transition mode="out-in">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="fetchKhachHang"
                      class="pointer"
                      color="#2196F3"
                      >mdi-refresh</v-icon
                    >
                  </template>
                  <span>Cập nhật lại danh sách</span>
                </v-tooltip>
              </v-slide-x-reverse-transition>
              <v-slide-x-reverse-transition mode="out-in">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="goAddKH"
                      class="pointer"
                      color="var(--primary-color)"
                      >mdi-plus-circle-outline</v-icon
                    >
                  </template>
                  <span>Thêm mới khách hàng</span>
                </v-tooltip>
              </v-slide-x-reverse-transition>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Kho</div>
          <v-autocomplete
            prepend-inner-icon="mdi-folder-account"
            v-model="form.kho"
            :items="listKho"
            outlined
            dense
            placeholder="Kho"
            item-text="name"
            item-value="id"
            hide-details
            :readonly="readonly"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Kiểu</div>
          <v-autocomplete
            prepend-inner-icon="mdi-folder-account"
            v-model="form.type"
            :items="TYPES"
            outlined
            dense
            placeholder="Kiểu"
            item-text="name"
            item-value="id"
            hide-details
            :readonly="readonly"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Hàng hoá</div>
          <v-autocomplete
            v-model="form.hang_hoa_id"
            :items="hanghoas"
            :loading="isLoadingHangHoa"
            placeholder="Hàng hoá"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details
            :item-text="(item) => `${item.ma} - ${item.ten}`"
            item-value="id"
            :readonly="readonly"
          >
            <template slot="no-data">
              <div class="text-center">
                {{
                  form.nha_cung_cap_id
                    ? "Chưa có dữ liệu"
                    : "Vui lòng chọn nhà cung cấp"
                }}
              </div>
            </template>
            <template v-if="!readonly" v-slot:append-outer>
              <v-slide-x-reverse-transition mode="out-in">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="goAddHH"
                      class="pointer"
                      color="var(--primary-color)"
                      >mdi-plus-circle-outline</v-icon
                    >
                  </template>
                  <span>Thêm mới hàng hoá</span>
                </v-tooltip>
              </v-slide-x-reverse-transition>
            </template>
          </v-autocomplete>
        </v-col>
        <!-- <v-col cols="3">
          <div class="label-form">Mã hàng</div>
          <v-text-field
            v-model="form.ma_hang"
            placeholder="Mã hàng"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details
          ></v-text-field>
        </v-col> -->

        <v-col cols="3">
          <div class="label-form">Số lượng</div>
          <v-text-field
            v-model="form.so_luong"
            placeholder="Số lượng"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="label-form">ĐVT</div>
          <v-text-field
            v-model="form.dvt"
            placeholder="ĐVT"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Giá trị mua</div>
          <v-text-field
            v-model="form.gia_tri_mua"
            placeholder="Giá trị mua"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Chiết khấu</div>
          <v-text-field
            v-model="form.chiet_khau"
            placeholder="Chiết khấu"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Số lượng trả lại</div>
          <v-text-field
            v-model="form.so_luong_tra_lai"
            placeholder="Số lượng trả lại"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Giá trị trả lại</div>
          <v-text-field
            v-model="form.gia_tri_tra_lai"
            placeholder="Giá trị trả lại"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Giá trị giảm giá</div>
          <v-text-field
            v-model="form.gia_tri_giam_gia"
            placeholder="Giá trị giảm giá"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Mã thống kê</div>
          <v-text-field
            v-model="form.ma_thong_ke"
            placeholder="Mã thống kê"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="label-form">HTXL</div>
          <v-text-field
            v-model="form.htxl"
            placeholder="HTXL"
            outlined
            dense
            prepend-inner-icon="mdi-rename-box"
            hide-details
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="label-form">Ghi chú</div>
          <v-textarea
            v-model="form.description"
            placeholder="Ghi chú"
            outlined
            dense
            prepend-inner-icon="mdi-note"
            no-resize
            :readonly="readonly"
            rows="1"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
    <DialogCreateProduct
      :customersList="khachhangs"
      ref="dialogProduct"
      @on-done="handleResultAddHH"
    />
  </PageListLayout>
</template>
<script>
import DateTimePickerComponent from "@/components/Date/date-time-picker";
import { getDanhMucCon } from "@/api/danhmuc.js";
import Api from "@/api/bienbangiaonhan.api";
import KHACHHANG from "@/api/khachhang";
import chatThaiAPI from "@/api/sx.chatthai.api";
import DialogCreateProduct from "./create-product-dialog.vue";
import { TYPES } from "@/constants/bienbangiaonhan";
export default {
  components: {
    DateTimePickerComponent,
    DialogCreateProduct,
  },
  data: () => ({
    TYPES,
    show: false,
    editing: false,
    loadingData: false,
    btnLoading: false,
    form: {
      type: 1,
    },
    khachhangs: [],
    hanghoas: [],
    nameRules: [(v) => !!v || "Tên không thể bỏ trống"],
    codeRules: [(v) => !!v || "Mã không thể bỏ trống"],
    listKho: [],
    productParams: {
      all_product: true,
    },
    isLoadingKhachHang: false,
    isLoadingHangHoa: false,
  }),
  watch: {
    "form.nha_cung_cap_id": {
      deep: true,
      handler(val) {
        if (val) {
          this.productParams = {
            all_product: false,
            customer_id: val,
            content_null_customer: true,
          };
        } else {
          this.productParams = {
            all_product: true,
            content_null_customer: true,
          };
        }
      },
    },
    "form.hang_hoa_id": {
      deep: true,
      handler(val) {
        let find = this.hanghoas.find((x) => x.id == val);
        if (find) {
          this.form.dvt = find.don_vi_tinh;
          // this.form.gia_tri_mua = find.unit_price;
        }
      },
    },
    productParams: {
      deep: true,
      handler(val) {
        this.fetchHangHoa();
      },
    },
  },
  computed: {
    readonly() {
      let path = this.$route.path;
      return path && path.includes("chitietbienbannghiemthu");
    },
    title() {
      if (this.readonly) {
        return "Chi tiết";
      }
      return this.editing ? "Cập nhật" : "Thêm mới";
    },
    linkBack() {
      return this.readonly
        ? "/kinhdoanh/bienbannghiemthu"
        : "/kinhdoanh/bienbangiaonhan";
    },
  },
  created() {
    let id = this.$route.params.id;
    if (id) {
      this.editing = true;
      this.fetchInfo();
    }
    Promise.all([this.fetchKho(), this.fetchKhachHang()]);
  },
  methods: {
    async handleSubmit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.editing) {
          await this.editItem();
        } else {
          await this.addItem();
        }
        this.$router.push({ path: this.linkBack});
      }
    },
    async fetchKhachHang() {
      try {
        this.isLoadingKhachHang = true;
        const res = await KHACHHANG.getAllCustomers({ select: ["id", "name", "code"] });
        this.khachhangs = res.data;
      } finally {
        this.isLoadingKhachHang = false;
      }
    },
    async fetchHangHoa(params = {}) {
      try {
        params = { ...this.productParams, ...params };
        this.isLoadingHangHoa = true;
        const res = await chatThaiAPI.list(params);
        this.hanghoas = res.data;
      } finally {
        this.isLoadingHangHoa = false;
      }
    },
    async fetchKho() {
      const res = await getDanhMucCon({ code: "DANHSACHKHO", isActive: true });
      this.listKho = res;
    },
    async fetchInfo() {
      this.loadingData = true;
      try {
        const res = await Api.getDetail(this.$route.params.id);
        this.form = res;
      } finally {
        this.loadingData = false;
      }
    },
    goAddHH() {
      let params = this.form.nha_cung_cap_id
        ? { customer_id: this.form.nha_cung_cap_id }
        : {};
      this.$refs.dialogProduct.createItem(params);
    },
    async handleResultAddHH() {
      await this.fetchHangHoa({ orderBy: "created_at" });
      if (this.hanghoas.length > 0) {
        this.form.nha_cung_cap_id = this.hanghoas[0].id;
      }
    },
    async addItem() {
      this.btnLoading = true;
      try {
        await Api.create(this.form);
        this.$toast.info("Thêm thành công", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
      } finally {
        this.btnLoading = false;
      }
    },
    goAddKH() {
      const { href } = this.$router.resolve({
        path: "/quanlykhachhang/themmoikhachhang",
      });
      window.open(href, "_blank");
    },
    async editItem() {
      this.btnLoading = true;
      try {
        await Api.update(this.form.id, this.form);
      } finally {
        this.btnLoading = false;
      }
    },
  },
};
</script>
